<template>
  <div class="searchresultbox">
    <!-- <div class="tagrecommended px-2" v-if="isFcSupplied">{{$t("sabre.search-result.box.recommended")}}</div> -->

    <div class="proposedflight_box" v-if="isFcSupplied">{{$t("sabre.search-result.box.recommended")}}</div>

    <div class="row align-items-center">
      <!---------- Flight info part---------------->
      <div class="col-lg-9 searchresultbox_right">
        <one-flight-item :data="data.legs[0]" :fcFlight="isFcSupplied"/>
        <one-flight-item :isBack="true" :data="data.legs[1]" :fcFlight="isFcSupplied"/>
      </div>

      <!---------- Price part---------------->
      <div class="col-lg-3">
        <div class="searchresultbox_left">

          <price-box :totalPrice="totalPrice"/>

          <div class="d-block btnarea">
            <a href="#" class="btn btn-warning w-100" @click="() => orderFlight()">{{$t("sabre.buttons.order")}}</a>
            <a href="#" class="btn btn-outline-primary w-100" @click="() => changeFlight()">
              {{$t("sabre.buttons.change-return-flight")}}
            </a>
          </div>
          <a class="Closedetail_btn collapsed" data-bs-toggle="collapse" :href="`#Closedetail1-${_uid}`" role="button">
            {{$t("sabre.buttons.more-detail")}}
          </a>
        </div>
      </div>

    </div>

    <div class="collapse flightdetailscollapse" :id="`Closedetail1-${_uid}`">
      <collapse-flight-info :data="data" :fcFlight="isFcSupplied"/>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  components: {
    CollapseFlightInfo: () => import('./searchResultBoxAtom/collapseFlightInfo'),
    OneFlightItem: () => import('./searchResultBoxAtom/oneFlightItem'),
    PriceBox: () => import('./searchResultBoxAtom/priceBox'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      itinGroups: 'GET_SABRE_FLIGHT_ITIN_GROUPS',
      searchQuery: 'GET_SABRE_FLIGHT_SEARCH_QUERY',
      isStaging: 'GET_STAGING_MODE',
    }),
    isFcSupplied() {
      const { itinGroups, data } = this;
      return itinGroups?.find((itin) => itin.id === data.itinGroupRef)?.fcSupplied;
    },
    totalPrice() {
      const { data } = this;
      return data.excerpt?.totalPrice || 0;
    },
  },
  methods: {
    orderFlight() {
      const stagingParam = this.isStaging ? '/?staging=true' : '';
      this.$store.dispatch('SABRE_ORDER_FLIGHT', { flightId: this.data.id });
      this.$router.push({ path: `/sabre/fo-booking-passenger${stagingParam}` });
    },
    changeFlight() {
      this.$store.dispatch('SABRE_CHANGE_FLIGHT', { flightId: this.data.id });
      this.$router.push({ path: `/sabre/fo-change-flights/?${this.searchQuery}&changeFlightID=${this.data.id}` });
    },
  },
};
</script>
